/**
 * @file: routes/user
 * @author yun
 */

import loadable from 'react-loadable'
import { Spin } from 'cgtd'

const Loading = Spin

const userRouters = [
    {
        path: '/login',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'user' */ /* webpackMode: "lazy" */ '@views/login'),
            loading: Loading,
        }),
    },
    // {
    //     path: '/register',
    //     exact: true,
    //     component: loadable({
    //         loader: () =>
    //             import('@views/register'),
    //         loading: Loading
    //     })
    // }
]

export default userRouters
