/**
 * @file: routes/card_news
 * @author yun
 */

import loadable from 'react-loadable'
import { Spin } from 'cgtd'

const Loading = Spin

const cardsRouters = [
    {
        path: '/cards',
        exact: true,
        component: loadable({
            loader: () => import('@views/page-cards'),
            loading: Loading,
        }),
    },
    {
        path: '/card/:card_id',
        exact: true,
        component: loadable({
            loader: () => import('@views/page-card'),
            loading: Loading,
        }),
    },
]

export default cardsRouters
