/**
 * @file 页面的头部
 * @author yun
 */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip, Image } from 'cgtd'
import { LogoutOutlined } from '@ant-design/icons'
import logo from '@assets/logo.png'

import './index.less'

const PREFIX = 'app-header'

const AppHeader = (props) => {
    const history = useHistory()

    const username = localStorage.getItem('username')

    const onStorageCB = (e) => {
        console.log('storage change::::', e)
        if (!localStorage.getItem('access-token')) {
            history.push('/login')
        }
    }

    // 登出
    const logout = async () => {
        localStorage.removeItem('access-token')
        localStorage.removeItem('refresh-token')
        localStorage.removeItem('username')
        history.push('/login')
    }

    // 监听storage事件变化
    useEffect(() => {
        window.addEventListener('storage', onStorageCB)
        return () => {
            window.removeEventListener('storage', onStorageCB)
        }
    }, [])

    if (!localStorage.getItem('access-token')) {
        history.push('/login');
    }

    return (
        <div className={PREFIX}>
            <div className={`${PREFIX}-content`}>
                <div className={`${PREFIX}-left`}>
                    <div className={`${PREFIX}-left-logo`}>
                        <Image preview={false} src={logo}></Image>
                    </div>
                    <div className={`${PREFIX}-left-title`}>运营数据统计平台</div>
                </div>
                <div className={`${PREFIX}-right`} onClick={logout}>
                    <Tooltip
                        placement='bottom'
                        title={username ? username : ''}
                    >
                        <LogoutOutlined /> {'Log out'}
                    </Tooltip>
                </div>
            </div>
        </div >
    )
}

export default AppHeader
