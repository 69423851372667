/**
 * @file main
 * @author gonna01
 */
import {Route, Switch, withRouter} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {Layout} from 'cgtd';
import routes from '@/configure/routes';
import NoFound from '@views/no-found';
import './index.less';

const LayoutMain = props => {

    return (
        <Layout className="main-area">
            <Switch>
                {renderRoutes(routes)}
                <Route key="noFound" component={NoFound} />
            </Switch>
        </Layout>
    );
};

export default withRouter(LayoutMain);