/**
 * @file 页面列表
 * @author yun
 *
 * path: 页面url
 * page: 页面代码（待用）
 * label: 页面标题
 * submenu: 是否为二级目录
 * import: 列表操作区是否有导入功能
 * filter: 页面需要的筛选功能模块
 * statistics: 页面统计区显示的模块以及列表展示的列
 * columns: 列表列中是否包含 "标题、作者、更新时间"
 * edit: 列表列中是否包含编辑功能
 * 
 * 筛选区filter相关参数如下：
 * 日期：1
 * 区制中心：2
 * 板块：3
 * 关键词：4
 * 类型：5
 * 平台类型：6
 * 
 * 统计区statistics和列表列columns的相关参数如下：
 * 发稿量：1
 * 阅读量：2
 * 独立用户：3
 * 视频播放量：4
 * 曝光量：5
 * 分享量：6
 * 点赞量：7
 * 收藏量：8
 * 评论量：9
 * 互动量：10
 * 翻译量：11
 * 平台：12
 * 板块：13
 */

export const pageList = [
    // {
    //     path: '/',
    //     page: 'all_platform',
    //     api: 'all_platform',
    //     label: '全平台汇总数据',
    //     card_label: '全平台汇总',
    //     submenu: false,
    //     import: false,
    //     filter: [1],
    //     statistics: [1, 2, 3, 4, 10],
    //     columns: [12, 1, 2, 3, 4, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: false,
    //     edit: false,
    //     cardAddNews: false,
    //     dataDelete: false
    // },
    // {
    //     path: '/all_platform_search',
    //     page: 'all_platform_search',
    //     api: 'all_platform_search',
    //     label: '全平台搜索',
    //     card_label: '全平台搜索',
    //     submenu: false,
    //     import: false,
    //     filter: [1, 4, 6],
    //     statistics: [2, 3, 4, 6, 7, 8, 9, 10],
    //     columns: [12, 2, 3, 4, 6, 7, 8, 9, 10],
    //     allowDatePickerEmpty: true,
    //     hasTitle: true,
    //     edit: false,
    //     cardAddNews: false,
    //     dataDelete: false
    // },
    {
        path: '/',
        page: 'own_platform',
        api: 'own_platform',
        label: '自有平台汇总数据',
        card_label: '自有平台汇总',
        submenu: false,
        import: false,
        filter: [1],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [12, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: false,
        edit: false,
        cardAddNews: false,
        dataDelete: false
    },
    {
        path: '/own/web',
        page: 'website',
        api: 'own_platform/website',
        label: '网站传播数据',
        card_label: '网站',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: true,
        cardAddNews: true,
        dataDelete: true
    },
    {
        path: '/own/app',
        page: 'app',
        api: 'own_platform/app',
        label: '客户端传播数据',
        card_label: '客户端',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: true,
        cardAddNews: true,
        dataDelete: true
    },
    {
        path: '/own/website-app',
        page: 'website-app',
        api: 'own_platform/website-app',
        label: '网站/客户端传播数据',
        card_label: '网站/客户端',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: false,
        cardAddNews: false,
        dataDelete: false
    },
    {
        path: '/own_ga4',
        page: 'own_platform_ga4',
        api: 'own_platform_ga4',
        label: '自有平台汇总数据(GA4)',
        card_label: '自有平台汇总',
        submenu: false,
        import: false,
        filter: [1],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [12, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: false,
        edit: false,
        cardAddNews: false,
        dataDelete: false
    },
    {
        path: '/own_ga4/web',
        page: 'website_ga4',
        api: 'own_platform_ga4/website',
        label: '网站传播数据(GA4)',
        card_label: '网站',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: true,
        cardAddNews: true,
        dataDelete: true
    },
    {
        path: '/own_ga4/app',
        page: 'app_ga4',
        api: 'own_platform_ga4/app',
        label: '客户端传播数据(GA4)',
        card_label: '客户端',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: true,
        cardAddNews: true,
        dataDelete: true
    },
    {
        path: '/own_ga4/website-app',
        page: 'website-app',
        api: 'own_platform_ga4/website-app',
        label: '网站/客户端传播数据(GA4)',
        card_label: '网站/客户端',
        submenu: true,
        import: false,
        filter: [1, 3, 4],
        statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
        columns: [13, 2, 3, 4, 6, 7, 8, 9, 10],
        allowDatePickerEmpty: false,
        hasTitle: true,
        edit: false,
        cardAddNews: false,
        dataDelete: false
    },
    // {
    //     path: '/socials',
    //     page: 'socials',
    //     api: 'social_platform',
    //     label: '社交平台汇总数据',
    //     card_label: '社交平台汇总',
    //     submenu: false,
    //     import: false,
    //     filter: [1, 5],
    //     statistics: [1, 2, 3, 4, 6, 7, 8, 9, 10],
    //     columns: [12, 1, 2, 3, 4, 6, 7, 8, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: false,
    //     edit: false,
    //     cardAddNews: false,
    //     dataDelete: false
    // },
    // {
    //     path: '/socials/twitter',
    //     page: 'twitter',
    //     api: 'social_platform/twitter',
    //     label: 'Twitter',
    //     card_label: 'Twitter',
    //     submenu: true,
    //     import: '.csv, .xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 2, 4, 6, 7, 9, 10],
    //     columns: [2, 4, 6, 7, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/facebook',
    //     page: 'facebook',
    //     api: 'social_platform/facebook',
    //     label: 'Facebook',
    //     card_label: 'Facebook',
    //     submenu: true,
    //     import: '.csv, .xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 2, 3, 4, 6, 7, 9, 10],
    //     columns: [2, 3, 4, 6, 7, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/youtube',
    //     page: 'youtube',
    //     api: 'social_platform/youtube',
    //     label: 'Youtube',
    //     card_label: 'Youtube',
    //     submenu: true,
    //     import: false,
    //     filter: [1, 4],
    //     statistics: [1, 4, 7, 8, 9, 10],
    //     columns: [4, 7, 8, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/weibo',
    //     page: 'weibo',
    //     api: 'social_platform/weibo',
    //     label: '微博',
    //     card_label: '微博',
    //     submenu: true,
    //     import: '.csv, .xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 2, 4, 6, 7, 9, 10],
    //     columns: [2, 4, 6, 7, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/wechat',
    //     page: 'wechat',
    //     api: 'social_platform/wechat',
    //     label: '微信公众号',
    //     card_label: '微信公众号',
    //     submenu: true,
    //     import: '.xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 2, 6, 10],
    //     columns: [2, 6, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/toutiaohao',
    //     page: 'toutiaohao',
    //     api: 'social_platform/toutiaohao',
    //     label: '头条号',
    //     card_label: '头条号',
    //     submenu: true,
    //     import: '.xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 2, 4, 6, 7, 8, 9, 10],
    //     columns: [2, 4, 6, 7, 8, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/wetv',
    //     page: 'wetv',
    //     api: 'social_platform/wetv',
    //     label: '腾讯视频',
    //     card_label: '腾讯视频',
    //     submenu: true,
    //     import: false,
    //     filter: [1, 4],
    //     statistics: [1, 4],
    //     columns: [4],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/douyin',
    //     page: 'douyin',
    //     api: 'social_platform/douyin',
    //     label: '抖音',
    //     card_label: '抖音',
    //     submenu: true,
    //     import: '.xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 4, 6, 7, 8, 9, 10],
    //     columns: [4, 6, 7, 8, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/kuaishou',
    //     page: 'kuaishou',
    //     api: 'social_platform/kuaishou',
    //     label: '快手',
    //     card_label: '快手',
    //     submenu: true,
    //     import: '.xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 4, 6, 7, 9, 10],
    //     columns: [4, 6, 7, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/yangshipin',
    //     page: 'yangshipin',
    //     api: 'social_platform/yangshipin',
    //     label: '央视频',
    //     card_label: '央视频',
    //     submenu: true,
    //     import: false,
    //     filter: [1, 4],
    //     statistics: [1, 4, 6, 7, 9, 10],
    //     columns: [4, 6, 7, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // },
    // {
    //     path: '/socials/bilibili',
    //     page: 'bilibili',
    //     api: 'social_platform/bilibili',
    //     label: '哔哩哔哩',
    //     card_label: '哔哩哔哩',
    //     submenu: true,
    //     import: '.xlsx',
    //     filter: [1, 4],
    //     statistics: [1, 4, 6, 7, 8, 9, 10],
    //     columns: [4, 6, 7, 8, 9, 10],
    //     allowDatePickerEmpty: false,
    //     hasTitle: true,
    //     edit: true,
    //     cardAddNews: true,
    //     dataDelete: true
    // }
]

export const infoMap = [
    {
        title: '平台',
        value: 'platform_name',
        key: 12
    },
    {
        title: '板块',
        value: 'section',
        key: 13
    },
    {
        title: '发稿量',
        value: 'news_count',
        key: 1
    },
    {
        title: '阅读量',
        value: 'read_count',
        key: 2
    },
    {
        title: '独立用户',
        value: 'unique_user_count',
        key: 3
    },
    {
        title: '视频播放量',
        value: 'video_play_count',
        key: 4
    },
    {
        title: '曝光量',
        value: 'exposure_count',
        key: 5
    },
    {
        title: '分享量',
        value: 'share_count',
        key: 6
    },
    {
        title: '点赞量',
        value: 'like_count',
        key: 7
    },
    {
        title: '收藏量',
        value: 'collection_count',
        key: 8
    },
    {
        title: '评论量',
        value: 'comment_count',
        key: 9
    },
    {
        title: '互动量',
        value: 'interaction_count',
        key: 10
    },
    {
        title: '翻译量',
        value: 'translation_count',
        key: 11
    },
]
