/**
 * @file: routes/roster_list
 * @author yu
 */

import loadable from 'react-loadable'
import { Spin } from 'cgtd'

const Loading = Spin

const listRouters = [
    {
        path: '/roster_list',
        exact: true,
        component: loadable({
            loader: () => import('@views/page-list'),
            loading: Loading,
        }),
    }
]

export default listRouters
