/**
 * @file: routes/calendar
 * @author yu
 */

import loadable from 'react-loadable'
import { Spin } from 'cgtd'

const Loading = Spin

const calenderRouters = [
    {
        path: '/calendar',
        exact: true,
        component: loadable({
            loader: () => import('@views/page-calendar'),
            loading: Loading,
        }),
    }
]

export default calenderRouters
