/**
 * @file 布局文件
 * @author gonna01
 */
import React from 'react'
import { Layout } from 'cgtd'
import { withRouter } from 'react-router-dom'
import LayoutMain from '../layout-main'
import AppHeader from '@/components/app-header'
import { LeftMenu } from '@/components/left-menu'
import './index.less'

const PREFIX = 'primary-layout'

const PrimaryLayout = (props) => {
    const { history } = props

    return (
        <>
            {history?.location?.pathname === '/login' ? (
                <LayoutMain />
            ) : (
                <Layout className={PREFIX}>
                    <div className={`${PREFIX}-top`}>
                        <AppHeader {...props} />
                    </div>
                    <div className={`${PREFIX}-bottom`}>
                        <div className={`${PREFIX}-bottom-left`}>
                            <LeftMenu />
                        </div>
                        <div className={`${PREFIX}-bottom-content`}>
                            <LayoutMain />
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default withRouter(PrimaryLayout)
