/**
 * @file 页面标题
 * @author yun
 */
import React from 'react'
import { Menu } from 'cgtd'
import { useHistory, Link } from 'react-router-dom'
import { pageList } from '@tools/pageList'
import './index.less'

const PREFIX = 'left-menu'

const LeftMenu = (props) => {
    const history = useHistory()
    const path = history.location.pathname

    var items = []

    pageList.map((item, index) => {
        if (item.submenu) {
            items.push({
                className: `${PREFIX}-item ${PREFIX}-submenu`,
                label: <Link to={item.path}>{item.label}</Link>,
                key: item.path,
            })
        } else {
            items.push({
                className: `${PREFIX}-item`,
                label: <Link to={item.path}>{item.label}</Link>,
                key: item.path,
            })
        }
    })

    // items.push({
    //     className: `${PREFIX}-item`,
    //     label: <Link to={'/cards'}>{'项目卡片'}</Link>,
    //     key: '/cards',
    // })

    items.push({
        className: `${PREFIX}-item`,
        label: <Link to={'/calendar'}>{'传播效果报告上传下载'}</Link>,
        key: '/calendar',
    })

    items.push({
        className: `${PREFIX}-item`,
        label: <Link to={'/roster_list'}>{'人员清单'}</Link>,
        key: '/roster_list',
    })

    return (
        <Menu
            selectedKeys={[path]}
            items={items}
            mode={'inline'}
            theme={'dark'}
            className={PREFIX}
        />
    )
}

export { LeftMenu }
