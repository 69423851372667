/**
 * @file: routes/tables
 * @author yun
 */

import loadable from 'react-loadable'
import { Spin } from 'cgtd'
import { pageList } from '@tools/pageList'

const Loading = Spin

var routers = []

pageList.map((item, index) => {
    routers.push({
        path: item.path,
        exact: true,
        component: loadable({
            loader: () =>
                import(
                    /* webpackChunkName: 'dashboard' */ /* webpackMode: "lazy" */
                    '@views/page-tables'
                ),
            loading: Loading,
        }),
    })
})

export default routers
