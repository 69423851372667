/**
 * @file 入口文件
 * @author gonna01
 */
import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import PrimaryLayout from './layout/primary-layout';
import {ConfigProvider} from 'cgtd';
import zhCN from 'cgtd/lib/locale/zh_CN';
import './App.css';

function App() {
    return (
        <ReduxProvider store={store}>
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
            <PrimaryLayout />
            </ConfigProvider>
        </BrowserRouter>
        </ReduxProvider>
    );
}

export default App;
