/**
 * @file 封装请求
 */
import { Modal, message } from 'cgtd'
import axios from 'axios'
const release = window.location.protocol + '//' + window.location.host + '/'
// const local = 'http://localhost:3000';

export const API_SERVER = process.env.REACT_APP_BASE_URL
const base = process.env.REACT_APP_BASE_URL
/**
 * 未登录时进行重定向处理
 * @param {*} response
 */
function handleLogin(response) {
    const {
        data: { success, message },
    } = response
    const currentHref = window.location.href
    if (!success && message) {
        const { redirect, environment } = message
        if (redirect) {
            let locationHref = redirect
            if (environment === 'iam') {
                locationHref += encodeURI(`redirect=${encodeURI(currentHref)}`)
            }
            // console.log('locationHref', locationHref);
            window.location.href = locationHref
        }
    }
}

/* 创建axios实例 */
const service = axios.create({
    baseURL: release,
    // timeout: 50000
})
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/* request拦截器 */
service.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
                // 'x-monitor-token': localStorage.getItem('auth-token'),
                // Authorization: localStorage.getItem('Authorization')
                // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiaWlkcCJdLCJ1c2VyX25hbWUiOiJ7XCJpZFwiOjU3LFwiY3JlYXRlZERhdGVcIjpcIjIwMjEtMDgtMTMgMjA6MTM6MzFcIixcImxhc3RNb2RpZmllZERhdGVcIjpcIjIwMjEtMDgtMTMgMjA6MTM6MzFcIixcInVzZXJuYW1lXCI6XCJ0ZXN0TWFuYWdlclwiLFwicGFzc3dvcmRcIjpcImlpZHBcIixcImRpc3BsYXlOYW1lXCI6XCLmtYvor5Xnjq_looPnlKjmiLdcIixcIm1vYmlsZVwiOlwiMTUwMzUxNjYzNDlcIixcImVtYWlsXCI6XCJcIixcImlkYWFzVXNlcklkXCI6XCI5ZGRiNWEzN2U4NWY0NjIxODk4MjZiMDM1OWFlOGQwM1wiLFwiaWRhYXNFbnRlcnByaXNlSWRcIjpcIjgwMzM1NTcyODlkZTRjMjE5Mzg5NWJlNmM2M2JiYmJkXCIsXCJzdGF0dXNcIjpcIm51bGxcIn0iLCJzY29wZSI6WyJBRE1JTiIsIlVTRVIiLCJBUEkiXSwiZXhwIjoyMjQ5OTcyNzg4LCJhdXRob3JpdGllcyI6WyJhZG1pbiJdLCJqdGkiOiI2NDNjNDI0YS1lOGViLTQxZjEtODliYS0xNWVkMWU0MzEwYjAiLCJjbGllbnRfaWQiOiJpaWRwIn0.9Zgx8Fu3LCBNJ7WXR1gsdqHk_V_reFrWhrBiAgKKqSg'
            },
        }
    },
    (error) => {
        Promise.reject(error)
    }
)

/* respone拦截器 */
service.interceptors.response.use(
    (response = {}) => {
        const { status, data, statusText } = response
        // console.log('response', response)
        if (status === 200) {
            // 登录拦截跳转
            // handleLogin(response)
            return response
        }

        return Promise.reject(status)
    },
    (error) => {
        // 异常处理

        console.log(error.response)
        console.log('--------')
        if (error.response.status + '' === '401') {
            localStorage.removeItem('access-token')
            localStorage.removeItem('refresh-token')
            localStorage.removeItem('username')
            window.location = release + 'login'
        }
        return Promise.reject(error)
    }
)

export default service

function get(url) {
    return service({
        url,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        },
    })
}

function post(url, params = {}, otherParam) {
    // return service({
    //     url,
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json;charset=UTF-8',
    //     },
    //     data: params,
    //     cancelToken,
    // })
    console.log('post', url, params, otherParam)
    return service({
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: params,
        ...otherParam,
    })
}

export function getFile(url) {
    return service({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        },
    })
}

export const postNoToken = (url, params) => {
    return axios({
        method: 'post',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${base}${url}`,
        data: params,
    })
}

// export const upload = (url, params = {}) => {
//     return service({
//         url,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         },
//         data: params
//     });
// }

function deleteRquest(url, params = {}) {
    return service({
        url,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: params,
    })
}

function put(url, params = {}) {
    return service({
        url,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: params,
    })
}

const execJsonp = ({ url, cb, cbName }) => {
    const script = document.createElement('script')
    script.src = url
    document.body.appendChild(script)
    window[cbName] = (res) => {
        cb(res)
        document.body.removeChild(script)
    }
}

// jsonp
const jsonp = (url, params) =>
    new Promise((resolve, reject) => {
        try {
            execJsonp({
                url: url,
                cb: (res) => {
                    resolve(res)
                },
                ...params,
            })
        } catch (err) {
            reject(err)
        }
    })

export { get, post, deleteRquest, jsonp, put }

const download = async function (param = {}) {
    if (typeof param === 'string') {
        window.open(param)
        return
    }

    // 处理blob下载
    const { url, success, ...otherParam } = param
    await axios({
        url,
        method: 'post',
        data: otherParam,
        withCredentials: true,
        responseType: 'blob',
    })
        .then((response) => response)
        .then((res) => {
            const {
                data: blob,
                headers: { 'content-disposition': contentDisposition },
            } = res
            const fileName = decodeURIComponent(contentDisposition.split('=')[1], 'UTF-8')
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, fileName)
                return
            }
            const selfURL = window.URL || window.webkitURL
            url && selfURL.revokeObjectURL(url)
            const toBlobUrl = selfURL.createObjectURL(blob)
            const aEl = document.createElement('a')
            aEl.href = toBlobUrl
            aEl.download = fileName
            aEl.click()
            success?.()
        })
        .catch((error) => {
            message.error(`${url} 下载失败 ${error}`)
        })
}

export { download }

const upload = (params = {}) => {
    const { url, onUploadProgress, file, ...otherParams } = params
    console.log('params:::', params)
    console.log('data:::', file)
    const param = new FormData()
    param.append('file', file)
    param.append('image', file)
    console.log('param:::', param)
    console.log(param.get('file'))
    return axios({
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-monitor-token': localStorage.getItem('auth-token'),
        },
        data: param,
    })
    // return axios.post(url, param, {
    //     'Content-Type': 'multipart/form-data',
    //     'x-monitor-token': localStorage.getItem('auth-token')
    // },);
}

export { upload }
